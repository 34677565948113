import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DateTime } from "luxon";
import Modal from "react-modal";
Modal.setAppElement("#root");

import { addNewTime } from "../../store/Time/timeActions";
import { toggleSearchList } from "../../store/Header/headerActions";
import { toggleWindowStiky } from "../../store/Header/headerActions";

import addIcon from "../../img/add-icon.png";

export const Input = ({ isSearchOpen }) => {
  const dispatch = useDispatch();
  const inputRef = useRef(null);

  const [cities, setCities] = useState([]);
  const [value, setValue] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);

  const localTimeZone = DateTime.now().zoneName;

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const searchCity = async () => {
    try {
      const api =
        value.length === 0
          ? `https://data.opendatasoft.com/api/records/1.0/search/?dataset=geonames-all-cities-with-a-population-1000%40public&q=${value}&rows=50&sort=population&facet=timezone&refine.timezone=${localTimeZone}`
          : `https://data.opendatasoft.com/api/records/1.0/search/?dataset=geonames-all-cities-with-a-population-1000%40public&q=${value}&rows=50&sort=population`;
      const res = await fetch(api);
      const data = await res.json();
      setCities(data.records);
    } catch (err) {
      throw new Error(err);
    }
  };

  const submitChoise = (cityData) => {
    const id = "id" + new Date().getTime();
    dispatch(
      addNewTime({
        timeZone: DateTime.local().setZone(cityData.timeZone).zoneName,
        nameOfCity: cityData.cityName,
        nameOfCountry: cityData.countryName,
        id: id,
      })
    );
    dispatch(toggleSearchList(false));
    setValue("");
    dispatch(toggleWindowStiky(false));
  };

  useEffect(() => {
    searchCity();
  }, [value]);

  useEffect(() => {
    inputRef.current.focus();
  }, [cities]);

  //Some traubles with modal window it need only local state of visibility
  useEffect(() => {
    setIsOpen(isSearchOpen);
  }, []);

  return (
    <div className="header__add">
      <input
        ref={inputRef}
        type="text"
        value={value}
        onChange={(e) => handleChange(e)}
        className="header__input"
        placeholder="Search by city/country name"
      />

      <Modal
        isOpen={modalIsOpen}
        className="city__list-modal"
        overlayClassName="city__list-overlay"
        contentLabel="Example Modal"
      >
        <ul className="header__list container">
          {cities.length === 0 && value.length !== 0 && (
            <p className="input__text-noResult">No results</p>
          )}
          {cities.map((city, index) => {
            return (
              <li
                key={index}
                onClick={() =>
                  submitChoise({
                    cityName: city.fields.name,
                    countryName: city.fields.label_en,
                    timeZone: city.fields.timezone,
                  })
                }
                className="header__list-item"
              >
                <button className="header__list-button">
                  <p className="header__list-text">
                    {city.fields.name}/{city.fields.label_en}
                  </p>
                  <img
                    src={addIcon}
                    alt="add icon"
                    width={35}
                    height={35}
                    className="header__item-icon"
                  />
                </button>
              </li>
            );
          })}
        </ul>
      </Modal>
    </div>
  );
};
