import * as types from "./sidebarActionTypes";

export const chooseType = (payload) => ({
  type: types.CHOOSE_TYPE,
  payload,
});

export const chooseClockface = (payload) => ({
  type: types.CHOOSE_CLOCKFACE,
  payload,
});

export const chooseFormat = (payload) => ({
  type: types.CHOOSE_FORMAT,
  payload,
});
