export const LOCAL_TIME_INIT = "LOCAL_TIME_INIT";
export const ADD_NEW_TIME = "ADD_NEW_TIME";
export const CLEAR_DUPLICATE_CITY = "CLEAR_DUPLICATE_CITY";
export const SET_INITIAL_VALUE = "SET_INITIAL_VALUE";
export const RESET_INITIAL_VALUE = "RESET_INITIAL_VALUE";
export const DELETE_CLOCK_ITEM = "DELETE_CLOCK_ITEM";
export const TOGGLE_BLUR = "TOGGLE_BLUR";
export const GET_CHANGING_ID = "GET_CHANGING_ID";
export const GET_CHANGED_ID = "GET_CHANGED_ID";

export const CLEAR = "CLEAR";
