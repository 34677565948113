import * as types from "./timeActionTypes";

const initialState = {
  clocks: [
    {
      timeZone: null,
      nameOfCity: null,
      nameOfCountry: null,
      id: null,
    },
  ],
  duplicateCityName: "",
  initialValue: { milliseconds: 0 },
  isBlur: false,
  changingId: null,
  changedId: null,
};

export const timeReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOCAL_TIME_INIT: {
      return {
        ...state,
        clocks: state.clocks.map((clock, index) =>
          index === 0
            ? {
                ...clock,
                timeZone: action.payload.timeZone,
                nameOfCity: action.payload.nameOfCity,
                nameOfCountry: action.payload.nameOfCountry,
                id: action.payload.id,
              }
            : { ...clock }
        ),
      };
    }
    case types.ADD_NEW_TIME: {
      const duplicate = state.clocks.find(
        (clock) =>
          clock.nameOfCity === action.payload.nameOfCity &&
          clock.timeZone === action.payload.timeZone
      );

      if (duplicate) {
        return {
          ...state,
          duplicateCityName: action.payload.nameOfCity,
        };
      } else {
        return {
          ...state,
          clocks: [...state.clocks, action.payload],
        };
      }
    }

    case types.CLEAR_DUPLICATE_CITY: {
      return {
        ...state,
        duplicateCityName: "",
      };
    }

    case types.SET_INITIAL_VALUE: {
      return {
        ...state,
        initialValue: action.payload,
      };
    }

    case types.RESET_INITIAL_VALUE: {
      return {
        ...state,
        initialValue: 0,
      };
    }

    case types.DELETE_CLOCK_ITEM: {
      return {
        ...state,
        clocks: state.clocks.filter((clock) => clock.id !== action.payload),
      };
    }

    case types.TOGGLE_BLUR: {
      return {
        ...state,
        isBlur: action.payload,
      };
    }

    case types.GET_CHANGING_ID: {
      return {
        ...state,
        changingId: action.payload,
      };
    }
    case types.GET_CHANGED_ID: {
      return {
        ...state,
        changedId: action.payload,
      };
    }
    case types.CLEAR: {
      return {
        ...state,
        clocks: [
          {
            timeZone: null,
            offsetName: null,
            nameOfCity: null,
            id: null,
          },
        ],
      };
    }
    default: {
      return state;
    }
  }
};
