import clockFace1 from "../src/img/clock1.png";
import clockFace2 from "../src/img/clock2.png";
import clockFace3 from "../src/img/clock3.png";

import type1 from "../src/img/type1.png";
import type2 from "../src/img/type2.png";
import type3 from "../src/img/type3.png";

export const types = [
  {
    name: "Analog and digital",
    img: type1,
  },
  {
    name: "Only digital",
    img: type3,
  },
];

export const clockFaces = [
  {
    name: "Clockface 1",
    img: clockFace1,
  },
  {
    name: "Clockface 2",
    img: clockFace2,
  },
  {
    name: "Clockface 3",
    img: clockFace3,
  },
];
