import * as types from "./headerActionTypes";

const initialState = {
  isSearchOpen: false,
  isWindowStiky: false,
};

export const headerReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_SEARCH_LIST: {
      return {
        ...state,
        isSearchOpen: action.payload,
      };
    }
    case types.MAKE_WINDOW_STICKY: {
      return {
        ...state,
        isWindowStiky: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
