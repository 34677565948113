import * as types from "./headerActionTypes";

export const toggleSearchList = (payload) => ({
  type: types.TOGGLE_SEARCH_LIST,
  payload,
});

export const toggleWindowStiky = (payload) => ({
  type: types.MAKE_WINDOW_STICKY,
  payload,
});
