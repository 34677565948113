import React, { useEffect, useState } from "react";
import useComponentVisible from "../Hooks/OutsideHook";
import DateTimePicker from "react-datetime-picker";
import { useDispatch } from "react-redux";
import { DateTime } from "luxon";
import Modal from "react-modal";
Modal.setAppElement("#root");

import { deleteClockItem } from "../../store/Time/timeActions";
import { setInitialValue } from "../../store/Time/timeActions";
import { toggleBlur } from "../../store/Time/timeActions";
import { getChangingId } from "../../store/Time/timeActions";
import { getChangedId } from "../../store/Time/timeActions";

import { DigitalClock } from "../DigitalClock/DigitalClock";
import Clock from "react-clock";

import closeIcon from "../../img/close-button.png";
import defaultClockface from "../../img/clock1.png";
import editIcon from "../../img/menu-icon.png";
import "./ClockItem.css";
import "../../styles/TimePicker.css";

export const ClockItem = ({
  timeZone,
  initialValue,
  nameOfCity,
  id,
  changingId,
  changedId,
  clockType,
  clockface,
  formatAM,
}) => {
  const dispatch = useDispatch();
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const dateWithoutTimeZone = DateTime.fromISO(
    DateTime.now().plus(initialValue),
    {
      zone: timeZone,
    }
  )
    .toISO()
    .slice(0, 23);
  const hour =
    formatAM === true
      ? DateTime.fromISO(DateTime.now().plus(initialValue), {
          zone: timeZone,
        }).toFormat("hh")
      : DateTime.fromISO(DateTime.now().plus(initialValue), {
          zone: timeZone,
        }).toFormat("HH");

  const minute = DateTime.fromISO(DateTime.now().plus(initialValue), {
    zone: timeZone,
  }).toFormat("mm");

  const merdiem = DateTime.fromISO(DateTime.now().plus(initialValue), {
    zone: timeZone,
  }).toFormat("a");

  const day = DateTime.fromISO(
    DateTime.local().setZone(timeZone).plus(initialValue).toISO(),
    { zone: timeZone }
  ).toFormat("ccc");

  const date = DateTime.fromISO(
    DateTime.local().setZone(timeZone).plus(initialValue).toISO(),
    { zone: timeZone }
  ).toFormat("dd.LL.y ");

  const [analogTime, setAnalogTime] = useState("");
  const [nextDate, setNextDate] = useState(dateWithoutTimeZone);

  const [isColonVisible, setIsColonVisible] = useState(true);
  const [modalIsOpen, setIsOpen] = useState(false);

  const closeBlur = () => {
    dispatch(toggleBlur(false));
    dispatch(getChangingId(null));
  };

  const tickTime = () => {
    setAnalogTime(
      DateTime.local().setZone(timeZone).plus(initialValue).toISOTime()
    );
  };

  const startChange = () => {
    setIsComponentVisible(false);
    dispatch(toggleBlur(true));
    dispatch(getChangingId(id));
  };

  const openModal = () => {
    setIsOpen(true);
    setIsComponentVisible(false);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const submitChange = () => {
    const currentTime = DateTime.fromISO(DateTime.now(), { zone: timeZone });
    const nextTimeString = DateTime.fromISO(nextDate.toISOString())
      .toISO()
      .slice(0, 23);

    const nextTime = DateTime.fromISO(nextTimeString, {
      zone: timeZone,
    });

    const diffTime = nextTime.diff(currentTime).toObject();

    dispatch(setInitialValue(diffTime));
    dispatch(toggleBlur(false));
    dispatch(getChangingId(null));
    dispatch(getChangedId(id));
    console.log(nextDate, nextTimeString);
  };

  const deleteClock = () => {
    dispatch(deleteClockItem(id));
  };

  useEffect(() => {
    const intervalSecond = setInterval(() => tickTime(), 1000);

    setNextDate(new Date(dateWithoutTimeZone));

    return () => {
      clearInterval(intervalSecond);
    };
  }, [initialValue]);

  useEffect(() => {
    setIsColonVisible(!isColonVisible);
  }, [analogTime]);

  useEffect(() => {
    setNextDate(new Date(dateWithoutTimeZone));
  }, [minute, hour]);

  return (
    <li className={changingId === id ? "clock__item top" : "clock__item"}>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="delete-modal"
        overlayClassName="delete-overlay"
        contentLabel="Example Modal"
      >
        <div className="clock__title-wrapper"></div>
        <h1 className="modal__title">Are you sure to delete clock?</h1>
        <div className="modal__button-wrapper">
          <button className="modal__button" onClick={() => deleteClock(id)}>
            Yes, delete it
          </button>
          <button className="modal__button" onClick={closeModal}>
            No, don't delete it
          </button>
        </div>
      </Modal>

      <div className="clock__title-wrapper">
        <p className="clock__title">{nameOfCity}</p>
        <div ref={ref} className="clock__button-wrapper">
          {changingId !== id ? (
            <button className="clock__button-edit">
              <img
                src={editIcon}
                alt="edit icon"
                width={30}
                height={30}
                onClick={() => setIsComponentVisible(!isComponentVisible)}
                className="clock__button-edit-img"
              />
            </button>
          ) : (
            <button className="clock__button-exit" type="button">
              <img
                src={closeIcon}
                alt="close button"
                className="clock__button-exit-img"
                height={40}
                width={40}
                onClick={() => closeBlur()}
              />
            </button>
          )}
          {isComponentVisible && (
            <ul
              className={
                clockType === "Only digital"
                  ? "clock__action-list-digital"
                  : "clock__action-list"
              }
            >
              <li className="clock__action-item">
                <button
                  className={
                    clockType === "Only digital"
                      ? "clock__action-button-digital"
                      : "clock__action-button"
                  }
                  onClick={() => startChange()}
                >
                  Change
                </button>
              </li>
              <li className="clock__action-item">
                <button
                  className={
                    clockType === "Only digital"
                      ? "clock__action-button-digital"
                      : "clock__action-button"
                  }
                  disabled={id === 0}
                  onClick={() => openModal()}
                >
                  Delete
                </button>
              </li>
            </ul>
          )}
        </div>
      </div>
      {(initialValue.milliseconds > 60000 ||
        initialValue.milliseconds < -60000) &&
        changedId === id && <p className="clock__text-changed">Changed</p>}
      {clockType === "Analog and digital" && (
        <div className="clock__wrapper">
          <Clock value={analogTime} className="clock" />
          <img
            src={clockType === undefined ? defaultClockface : clockface}
            alt="clockface"
            className="clock__clockface"
          />
        </div>
      )}
      <div className="digital__clock-wrapper">
        {(clockType === "Only digital" || clockType === "Analog and digital") &&
          changingId !== id && (
            <DigitalClock
              hour={hour}
              minute={minute}
              day={day}
              isColonVisible={isColonVisible}
              date={date}
              formatAM={formatAM}
              merdiem={merdiem}
            />
          )}
        {changingId === id && (
          <div className="clock__edit-wrapper">
            <DateTimePicker
              calendarIcon={null}
              showLeadingZeros={true}
              autoFocus={true}
              clearIcon={null}
              onChange={setNextDate}
              value={nextDate}
              disableClock={true}
              format={formatAM === true ? "dd.MM.y h:m a" : "dd.MM.y HH:m"}
            />
            <button
              onClick={() => submitChange()}
              type="button"
              className="clock__button-submit"
            >
              OK
            </button>
          </div>
        )}
      </div>
    </li>
  );
};
