import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { types } from "../../static";
import { clockFaces } from "../../static";
import "./Sidebar.css";

import { chooseType } from "../../store/Sidebar/sidebarActions";
import { chooseClockface } from "../../store/Sidebar/sidebarActions";
import { chooseFormat } from "../../store/Sidebar/sidebarActions";

export const Sidebar = () => {
  const dispatch = useDispatch();
  const clockType = useSelector((state) => state.sidebarReducer.clockType);
  const clockface = useSelector((state) => state.sidebarReducer.clockface);
  const formatAM = useSelector((state) => state.sidebarReducer.formatAM);

  const [isTypesVisible, setIsTypesVisible] = useState(false);
  const [isClockfacesVisible, setIsClockfacesVisible] = useState(false);
  const [isFormatsVisible, setIsFormatesVisible] = useState(false);

  const openTypes = () => {
    setIsTypesVisible(!isTypesVisible);
    setIsClockfacesVisible(false);
    setIsFormatesVisible(false);
  };

  const openClockfaces = () => {
    setIsClockfacesVisible(!isClockfacesVisible);
    setIsFormatesVisible(false);
    setIsTypesVisible(false);
  };

  const openFormats = () => {
    setIsFormatesVisible(!isFormatsVisible);
    setIsClockfacesVisible(false);
    setIsTypesVisible(false);
  };

  const submitType = (type) => {
    dispatch(chooseType(type));
  };

  const submitClockface = (img) => {
    dispatch(chooseClockface(img));
  };

  const submitFormat = (format) => {
    dispatch(chooseFormat(format));
  };
  return (
    <nav className="sidebar">
      <h1 className="sidebar__title">Settings:</h1>
      <div className="sidebar__content">
        <button
          className="sidebar__button type"
          type="button"
          onClick={() => openTypes()}
        >
          Choose type
        </button>
        {isTypesVisible && (
          <ul className="sidebar__type-list sidebar__list">
            {types.map((type) => {
              return (
                <li
                  className={
                    type.name === clockType
                      ? "sidebar__type-item sidebar__item scaled"
                      : "sidebar__type-item sidebar__item"
                  }
                  key={type.name}
                >
                  <button
                    className="sidebar__item-button"
                    type="button"
                    onClick={() => submitType(type.name)}
                  >
                    {type.name}
                    <img
                      src={type.img}
                      alt="type clock image"
                      height={80}
                      width={80}
                      className="sidebar__type-img"
                    />
                  </button>
                </li>
              );
            })}
          </ul>
        )}
        {clockType !== "Only digital" && (
          <>
            <button
              className="sidebar__button clockface"
              type="button"
              onClick={() => openClockfaces()}
            >
              Choose clockface
            </button>
            {isClockfacesVisible && (
              <ul className="sidebar__clockface-list sidebar__list">
                {clockFaces.map((clockFace) => {
                  return (
                    <li
                      className={
                        clockFace.img === clockface
                          ? "sidebar__clockface-item sidebar__item scaled"
                          : "sidebar__clockface-item sidebar__item "
                      }
                      key={clockFace.name}
                    >
                      <button
                        type="button"
                        className="sidebar__item-button"
                        onClick={() => submitClockface(clockFace.img)}
                      >
                        {clockFace.name}
                        <img
                          src={clockFace.img}
                          alt="clockface type"
                          height={80}
                          width={80}
                        />
                      </button>
                    </li>
                  );
                })}
              </ul>
            )}
          </>
        )}

        <button
          className="sidebar__button format"
          type="button"
          onClick={() => openFormats()}
        >
          Choose format
        </button>
        {isFormatsVisible && (
          <ul className="sidebar__format-list sidebar__list">
            <li className="sidebar__format-item sidebar__item">
              <button
                type="button"
                className={
                  formatAM === true
                    ? "sidebar__item-button"
                    : "sidebar__item-button scaled"
                }
                onClick={() => submitFormat(false)}
              >
                24 hours
              </button>
            </li>
            <li className="sidebar__format-item sidebar__item">
              <button
                type="button"
                className={
                  formatAM === true
                    ? "sidebar__item-button scaled"
                    : "sidebar__item-button "
                }
                onClick={() => submitFormat(true)}
              >
                12 AM PM
              </button>
            </li>
          </ul>
        )}
      </div>
    </nav>
  );
};
