import "./DigitalClock.css";

export const DigitalClock = ({
  hour,
  minute,
  day,
  isColonVisible,
  date,
  formatAM,
  merdiem,
}) => {
  return (
    <div className="digital__clock">
      <div className="digital__clock-time">
        <p className="digital__clock-hour digital__clock-text">{hour}</p>
        <p
          className={
            isColonVisible === true
              ? "digital__clock-divider digital__clock-text"
              : "digital__clock-divider digital__clock-text transparent"
          }
        >
          :
        </p>
        <p className="digital__clock-minute digital__clock-text">{minute}</p>

        {formatAM && <p className="digital__clock-merdiem">{merdiem},</p>}
        <p className="digital__clock-day digital__clock-text">{day}</p>
      </div>
      <div className="digital__clock-date">
        <p className="digital__clock-date digital__clock-text">{date}</p>
      </div>
    </div>
  );
};
