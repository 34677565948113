import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Input } from "../Input/Input";
import { Sidebar } from "../Sidebar/Sidebar";

import { toggleSearchList } from "../../store/Header/headerActions";
import { toggleWindowStiky } from "../../store/Header/headerActions";

import "./Header.css";
import logo from "../../img/clock-icon.png";
import addIcon from "../../img/plus-icon.png";
import closeIcon from "../../img/close-button.png";
import burgerIcon from "../../img/burger-icon.svg";
import rightArrow from "../../img/right-arrow.png";

export const Header = () => {
  const dispatch = useDispatch();

  const isSearchOpen = useSelector((state) => state.headerReducer.isSearchOpen);
  const isBlur = useSelector((state) => state.timeReducer.isBlur);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = (value) => {
    setIsSidebarOpen(value);
    dispatch(toggleWindowStiky(value));
  };

  const toggleSearch = (value) => {
    dispatch(toggleSearchList(value));
    dispatch(toggleWindowStiky(value));
  };

  return (
    <section className="header">
      <div className="header__content container">
        <img
          src={logo}
          alt="clock icon"
          width={40}
          height={40}
          className="header__icon"
        />

        {isSearchOpen && <Input isSearchOpen={isSearchOpen} />}
        <div className="header__buttons-wrapper">
          {isSidebarOpen === false &&
            (isSearchOpen === false ? (
              <button
                className={
                  isBlur === true
                    ? "header__button-add invisible"
                    : "header__button-add"
                }
                type="submit"
                onClick={() => toggleSearch(true)}
              >
                <img
                  src={addIcon}
                  alt="add icon"
                  width={40}
                  height={40}
                  className="header__icon"
                />
              </button>
            ) : (
              <button
                className="header__button-close"
                type="submit"
                onClick={() => toggleSearch(false)}
              >
                <img
                  src={closeIcon}
                  alt="close icon"
                  width={45}
                  height={45}
                  className="header__button-close-img"
                />
              </button>
            ))}
          {isSearchOpen === false &&
            (isSidebarOpen === false ? (
              <button
                className={
                  isBlur === true
                    ? "header__menu-button invisible"
                    : "header__menu-button"
                }
                type="submit"
                onClick={() => toggleSidebar(true)}
              >
                <img
                  src={burgerIcon}
                  alt="menu logo"
                  width={40}
                  height={40}
                  className="header__icon"
                />
              </button>
            ) : (
              <button
                className={
                  isBlur === true
                    ? "header__menu-button invisible"
                    : "header__menu-button"
                }
                type="submit"
                onClick={() => toggleSidebar(false)}
              >
                <img
                  src={rightArrow}
                  alt="go back logo"
                  width={40}
                  height={40}
                  className="header__icon"
                />
              </button>
            ))}
        </div>
      </div>
      {isSidebarOpen && <Sidebar />}
    </section>
  );
};
