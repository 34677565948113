import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DateTime } from "luxon";

import { Blur } from "./components/Blur/Blur";
import { ClockItem } from "./components/ClockItem/ClockItem";
import { Header } from "./components/Header/Hedaer";
import { Footer } from "./components/Footer/Footer";

import { localTimeInit } from "./store/Time/timeActions";
import { resetInitialValue } from "./store/Time/timeActions";
import { getChangingId } from "./store/Time/timeActions";
import { toggleBlur } from "./store/Time/timeActions";
import { toggleSearchList } from "./store/Header/headerActions";
import { clearDuplicateCity } from "./store/Time/timeActions";

import refreshIcon from "./img/restart-icon.png";
import "./styles/Modal.css";
import "./App.css";

function App() {
  const dispatch = useDispatch();

  const clocks = useSelector((state) => state.timeReducer.clocks);
  const initialValue = useSelector((state) => state.timeReducer.initialValue);
  const duplicateCityName = useSelector(
    (state) => state.timeReducer.duplicateCityName
  );
  const isBlur = useSelector((state) => state.timeReducer.isBlur);
  const changingId = useSelector((state) => state.timeReducer.changingId);
  const changedId = useSelector((state) => state.timeReducer.changedId);
  const clockType = useSelector((state) => state.sidebarReducer.clockType);
  const clockface = useSelector((state) => state.sidebarReducer.clockface);
  const formatAM = useSelector((state) => state.sidebarReducer.formatAM);
  const isWindowStiky = useSelector(
    (state) => state.headerReducer.isWindowStiky
  );

  const localTimeZone = DateTime.now().zoneName;
  const nameOfCity = localTimeZone.split("/")[1];
  const [nameOfCountry, setNameOfCountry] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const reset = () => {
    dispatch(resetInitialValue());
  };

  useEffect(() => {
    dispatch(getChangingId(null));
    dispatch(resetInitialValue());
    dispatch(toggleBlur(false));
    dispatch(toggleSearchList(false));
    dispatch(clearDuplicateCity());
  }, []);

  useEffect(() => {
    dispatch(
      localTimeInit({
        timeZone: localTimeZone,
        nameOfCity: nameOfCity,
        nameOfCountry: nameOfCountry,
        id: 0,
      })
    );
  }, [nameOfCity]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(clearDuplicateCity());
    }, 2000);
    return () => {
      clearTimeout(timeout);
    };
  }, [duplicateCityName]);

  useEffect(() => {
    setIsLoading(true);
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 1);
    return () => {
      clearTimeout(timeout);
    };
  }, [clocks]);

  return (
    <div className={isWindowStiky ? "app stiky" : "app"}>
      {isBlur === true && <Blur />}
      <Header />
      {duplicateCityName && (
        <div className="modal">
          <p className="modal__text">
            You have already {duplicateCityName} in the list.
          </p>
        </div>
      )}
      <section className="main">
        <div className="container">
          <button className="refresh__button" onClick={() => reset()}>
            <img
              src={refreshIcon}
              alt="refresh icon"
              width={40}
              height={40}
              className="refresh__button-icon"
            />
          </button>
          <ul className="clock__list">
            {isLoading === true ? (
              <></>
            ) : (
              <>
                {clocks.map((clock) => {
                  return (
                    <ClockItem
                      timeZone={clock.timeZone}
                      initialValue={initialValue}
                      nameOfCity={clock.nameOfCity}
                      id={clock.id}
                      changingId={changingId}
                      changedId={changedId}
                      clockType={clockType}
                      clockface={clockface}
                      formatAM={formatAM}
                      key={clock.id}
                    />
                  );
                })}
              </>
            )}
          </ul>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default App;
