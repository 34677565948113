import * as types from "./timeActionTypes";

export const localTimeInit = (payload) => ({
  type: types.LOCAL_TIME_INIT,
  payload,
});

export const addNewTime = (payload) => ({
  type: types.ADD_NEW_TIME,
  payload,
});

export const clearDuplicateCity = () => ({
  type: types.CLEAR_DUPLICATE_CITY,
});

export const setInitialValue = (payload) => ({
  type: types.SET_INITIAL_VALUE,
  payload,
});

export const resetInitialValue = () => ({
  type: types.RESET_INITIAL_VALUE,
});

export const deleteClockItem = (payload) => ({
  type: types.DELETE_CLOCK_ITEM,
  payload,
});

export const toggleBlur = (payload) => ({
  type: types.TOGGLE_BLUR,
  payload,
});

export const getChangingId = (payload) => ({
  type: types.GET_CHANGING_ID,
  payload,
});

export const getChangedId = (payload) => ({
  type: types.GET_CHANGED_ID,
  payload,
});

export const clear = () => ({
  type: types.CLEAR,
});
