import * as types from "./sidebarActionTypes";
import defaultClockface from "../../img/clock1.png";

const initialState = {
  clockType: "Analog and digital",
  clockface: defaultClockface,
  formatAM: false,
};

export const sidebarReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHOOSE_TYPE: {
      return {
        ...state,
        clockType: action.payload,
      };
    }
    case types.CHOOSE_CLOCKFACE: {
      return {
        ...state,
        clockface: action.payload,
      };
    }

    case types.CHOOSE_FORMAT: {
      return {
        ...state,
        formatAM: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
