import "./Footer.css";

import linkedinIcon from "../../img/linkedin-icon.png";
import githubIcon from "../../img/github-icon.png";
import facebookIcon from "../../img/facebook-icon.png";
import instagramIcon from "../../img/instagram-icon.png";
import footerClock from "../../img/footer-clock.png";

export const Footer = () => {
  return (
    <section className="footer">
      <div className="container">
        <div className="footer__information-wrapper">
          <div className="footer__information">
            <img
              src={footerClock}
              alt="clock image"
              className="footer-img"
              width={100}
              height={100}
            />
            <div className="footer__text-wrapper">
              <h1 className="footer__title">Immigration clock by Chansky</h1>
              <p className="footer__copy">
                &copy; 2023 Brand All Rights Reserved.
              </p>
            </div>
          </div>
          <ul className="footer__link-list">
            <li className="footer__list-item">
              <a
                target="blank"
                href="https://www.linkedin.com/in/gleb-mesiagutov/"
                className="footer__item-link"
              >
                <img
                  width={30}
                  height={30}
                  src={linkedinIcon}
                  alt="linckedin icon"
                  className="footer__link-img"
                />
                <span className="footer__link-text">
                  linkedin.com/in/gleb-mesiagutov
                </span>
              </a>
            </li>
            <li className="footer__list-item">
              <a
                href="https://github.com/Gleb-Chansky"
                className="footer__item-link"
                target="blank"
              >
                <img
                  src={githubIcon}
                  alt="github icon"
                  width={30}
                  height={30}
                  className="footer__link-img"
                />
                <span className="footer__link-text">
                  github.com/Gleb-Chansky
                </span>
              </a>
            </li>
            <li className="footer__list-item">
              <a
                href="https://www.facebook.com/gleb.mesiagutov"
                className="footer__item-link"
                target="blank"
              >
                <img
                  src={facebookIcon}
                  alt="facebook icon"
                  width={30}
                  height={30}
                  className="footer__link-img"
                />
                <span className="footer__link-text">
                  facebook.com/gleb.mesiagutov
                </span>
              </a>
            </li>
            <li className="footer__list-item">
              <a
                href="https://www.instagram.com/mesiagutov/"
                className="footer__item-link"
                target="blank"
              >
                <img
                  src={instagramIcon}
                  alt="instagram icon"
                  width={30}
                  height={30}
                  className="footer__link-img"
                />
                <span className="footer__link-text">
                  instagram.com/mesiagutov/
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};
